var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.description
        ? _c(
            "Select",
            {
              attrs: {
                size: _vm.size,
                loading: _vm.loading,
                placeholder: _vm.placeholder,
                multiple: _vm.multiple,
                disabled: _vm.disabled,
                filterable: _vm.filterable,
                transfer: _vm.transfer,
                clearable: _vm.clearable,
                placement: _vm.placement,
                "not-found-text": _vm.notFoundText,
                "label-in-value": _vm.labelInValue,
                "transfer-class-name": _vm.transferClassName,
                prefix: _vm.prefix,
                "max-tag-count": _vm.maxTagCount,
                "max-tag-placeholder": _vm.maxTagPlaceholder,
              },
              on: {
                "on-change": _vm.handleChange,
                "on-query-change": _vm.handleQueryChange,
                "on-clear": _vm.handleClear,
                "on-open-change": _vm.handleOpenChange,
                "on-select": _vm.handleSelect,
              },
              model: {
                value: _vm.currentValue,
                callback: function ($$v) {
                  _vm.currentValue = $$v
                },
                expression: "currentValue",
              },
            },
            _vm._l(_vm.data, function (item, i) {
              return _c("Option", { key: i, attrs: { value: item.value } }, [
                _vm._v(_vm._s(item.title)),
              ])
            }),
            1
          )
        : _vm._e(),
      _vm.description
        ? _c(
            "Select",
            {
              attrs: {
                size: _vm.size,
                loading: _vm.loading,
                placeholder: _vm.placeholder,
                multiple: _vm.multiple,
                disabled: _vm.disabled,
                filterable: _vm.filterable,
                transfer: _vm.transfer,
                clearable: _vm.clearable,
                placement: _vm.placement,
                "not-found-text": _vm.notFoundText,
                "label-in-value": _vm.labelInValue,
                "transfer-class-name": _vm.transferClassName,
                prefix: _vm.prefix,
                "max-tag-count": _vm.maxTagCount,
                "max-tag-placeholder": _vm.maxTagPlaceholder,
              },
              on: {
                "on-change": _vm.handleChange,
                "on-query-change": _vm.handleQueryChange,
                "on-clear": _vm.handleClear,
                "on-open-change": _vm.handleOpenChange,
                "on-select": _vm.handleSelect,
              },
              model: {
                value: _vm.currentValue,
                callback: function ($$v) {
                  _vm.currentValue = $$v
                },
                expression: "currentValue",
              },
            },
            _vm._l(_vm.data, function (item, i) {
              return _c(
                "Option",
                { key: i, attrs: { value: item.value, label: item.title } },
                [
                  _c("span", { staticStyle: { "margin-right": "10px" } }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                  _c("span", { staticStyle: { color: "#ccc" } }, [
                    _vm._v(_vm._s(item.description)),
                  ]),
                ]
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }